<template>
    <div class="company">
        <headLine msg='企业信息'></headLine>   
        <div class="companyInfo">
            <el-form   :model="ruleForm" :rules="rules"
                ref="ruleForm" label-width="120px" class="demo-ruleForm" label-position="right">
                <el-form-item label="公司名称：" prop="companyName">
                    <el-input clearable v-model="ruleForm.companyName" placeholder="请输入公司名称"></el-input>
                </el-form-item>
                <el-form-item label="行业类别：" prop="companyTradeId">
                    <el-select clearable v-model="ruleForm.companyTradeId" placeholder="请选择公司所在行业类别">
                    <el-option v-for="(v,i) in companyTradeList" :key="i" 
                    :label="v.companyTrade" :value="v.id"></el-option>  
                    </el-select>
                </el-form-item>
                <el-form-item  label="公司规模：" prop="companyScale">
                    <el-select clearable v-model="ruleForm.companyScale" placeholder="请选择公司规模">
                    <el-option 
                    v-for="(v,i) in companyScaleList" :key=" i"
                    :label="v" :value="v"></el-option>                
                    </el-select>
                </el-form-item>
                <el-form-item label="所在区域：" prop="companyProviceAndCity">
                    <el-cascader
                        clearable
                        :options="options"
                        v-model="ruleForm.companyProviceAndCity"
                        :props="props"
                        placeholder="请选择公司所在区域">
                    </el-cascader>
                </el-form-item>
                <el-form-item label="详细地址：" prop="companyAddress">
                    <el-input clearable v-model="ruleForm.companyAddress" placeholder="请输入公司地址"></el-input>
                </el-form-item>
                <el-form-item label="组织机构代码：" prop="orgCode">
                    <el-input clearable v-model="ruleForm.orgCode" placeholder="请输入公司组织机构代码"></el-input>
                </el-form-item>
                <el-form-item label="公司网站：" prop="companyWebsite">
                    <el-input clearable v-model="ruleForm.companyWebsite" placeholder="请输入公司官网地址"></el-input>
                </el-form-item>
                    <el-form-item label="公司简介：" prop="companyIntroduce">
                    <el-input clearable type="textarea" v-model="ruleForm.companyIntroduce" 
                    rows="4" placeholder="请简单介绍公司"></el-input>
                </el-form-item>
                <div class="btnBox">  
                    <button type="button" class="btns" @click="submitForm('ruleForm')">保存</button>  
                </div>
            </el-form>
        </div>
    </div>
</template>
<script>
import { provinceAndCityData } from 'element-china-area-data'
import {companyScaleList} from '../../service/config'
export default {
    data() {
        return {
           companyScaleList,
           props:{value:'label'},
           options: provinceAndCityData,
           ruleForm:{},
           companyTradeList:[],
           comId:'',
           rules:{companyName: [{ required: true, message: '请输入公司名称', trigger: 'blur' },],
                companyTradeId: [
                    { required: true, message: '请选择公司所在行业类别', trigger: 'change' }
                ],
                orgCode:[{ required: true, message: '请输入公司组织机构代码', trigger: 'blur' },],
                companyScale:[{ required: true, message: '请选择公司规模', trigger: 'blur' },],
                companyAddress:[{ required: true, message: '请输入公司地址', trigger: 'blur' },],
                companyIntroduce:[{ required: true, message: '请简单介绍公司', trigger: 'blur' },],
                companyProviceAndCity:[{ required: true, message: '请选择公司所在区域', trigger: 'change' },]
            },
           
        }
    },
    methods:{
        submitForm(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {  
                    this.ruleForm.province=this.ruleForm.companyProviceAndCity[0]
                    this.ruleForm.city=this.ruleForm.companyProviceAndCity[1]
                    this.$htp.put_('/hr/company/update',this.ruleForm,true).then(()=>{
                        this.getInitList()
                    })
                } else {
                    return false;
                }
            });
        },
        getCompanyTradeList(){
            this.$htp.get_('/hr/company/trade/list').then(res=>{
                this.companyTradeList=res.data
                this.getInitList()
            })
        },
        getInitList(){
            this.$htp.get_('/hr/company/get?companyId='+this.comId).then(res=>{
                let data=res.data
                this.ruleForm={...data}
                this.ruleForm.companyProviceAndCity=[data.province,data.city]
            })
        } 
    },
    mounted(){
        this.comId=sessionStorage.getItem('id')
        this.getCompanyTradeList()
        
    }
}
</script>
<style lang="scss" scoped>
@import '../../assets/css/companyInfo.scss';
</style>